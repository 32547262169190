<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    persistent
    width="340px"
    :disabled="disabled || readonly">
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormat"
        prepend-inner-icon="mdi-calendar-blank-outline"
        :append-icon="clearable && dateFormat ? 'mdi-close' : ''"
        :placeholder="placeholder"
        readonly
        outlined
        dense
        hide-details
        background-color="white"
        :rules="rules"
        :disabled="disabled"
        v-bind="attrs"
        v-on="on"
        @click:append="clearValue()">
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :min="minDate"
      :max="maxDate"
      :type="type"
      range>
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="onCancel()">
        ยกเลิก
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="onConfirm()">
        ยืนยัน
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    value: {
      type: [String, Array],
      default: null
    },
    type: {
      type: String,
      default: 'date'
    },
    format: {
      type: String,
      default: 'D MMM BB'
    },
    placeholder: {
      type: String,
      default: 'วัน เดือน ปี'
    },
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    clearable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    modal: false,
    date: dayjs().format('YYYY-MM-DD HH:mm')
  }),
  computed: {
    isEmpty () {
      return !this.value?.length || this.value?.some((v) => !v)
    },
    dateFormat () {
      if (this.isEmpty) {
        return ''
      }

      const startDate = this.$dayjs(this.value[0]).locale('th').format(this.format)
      const endDate = this.$dayjs(this.value[1] || this.value[0]).locale('th').format(this.format)

      return `${startDate} - ${endDate}`
    },
    minDate () {
      if (!this.min) {
        return ''
      }

      return this.$dayjs(this.min).add(7, 'h').format('YYYY-MM-DD HH:mm')
    },
    maxDate () {
      if (!this.max) {
        return ''
      }

      return this.$dayjs(this.max).add(7, 'h').format('YYYY-MM-DD HH:mm')
    }
  },
  mounted () {
    if (this.isEmpty && this.max) {
      const formated = this.$dayjs(this.max).format('YYYY-MM-DD HH:mm')
      this.date = [formated, formated]
    } else if (this.isEmpty) {
      this.date = ['', '']
    } else {
      const startDate = this.$dayjs(this.value[0]).format('YYYY-MM-DD HH:mm')
      const endDate = this.$dayjs(this.value[1] || this.value[0]).format('YYYY-MM-DD HH:mm')
      this.date = [startDate, endDate]
    }
  },
  methods: {
    clearValue () {
      const today = this.$dayjs().format('YYYY-MM-DD HH:mm')
      this.date = [today, today]
      this.$emit('input', ['', ''])
    },
    onCancel () {
      this.modal = false

      if (this.isEmpty) {
        const today = this.$dayjs().format('YYYY-MM-DD HH:mm')
        this.date = [today, today]
      } else {
        const startDate = this.$dayjs(this.value[0]).format('YYYY-MM-DD HH:mm')
        const endDate = this.$dayjs(this.value[1] || this.value[0]).format('YYYY-MM-DD HH:mm')
        this.date = [startDate, endDate]
      }
    },
    onConfirm () {
      const today = this.$dayjs().format('HH:mm')
      const startTime = !this.isEmpty ? this.$dayjs(this.value[0]).format('HH:mm') : today
      const endTime = !this.isEmpty ? this.$dayjs(this.value[1] || this.value[0]).format('HH:mm') : today
      const mergedDate = [
        this.$dayjs(`${this.date[0]} ${startTime}`).toISOString(),
        this.$dayjs(`${this.date[1]} ${endTime}`).toISOString()
      ]

      this.$emit('input', mergedDate)

      this.modal = false
    }
  }
}
</script>

<style scoped>

</style>
