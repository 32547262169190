import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class ReportsProvider extends HttpRequest {
  getSalesReport (query) {
    this.setHeader(getAuthToken())
    return this.get('/reports/sales-report', query)
  }

  getSalesReportByPackages (query) {
    this.setHeader(getAuthToken())
    return this.get('/reports/sales-report/packages', query)
  }

  getSalesReportByTourId (id, query) {
    this.setHeader(getAuthToken())
    return this.get(`/reports/sales-report/${id}`, query)
  }

  getSalesReportByVendorId (id, query) {
    this.setHeader(getAuthToken())
    return this.get(`/reports/sales-report/${id}/vendor`, query)
  }

  getSalesReportByPackage (id, payload, query) {
    this.setHeader(getAuthToken())
    return this.get(`/reports/sales-report/${id}/package/${payload}`, query)
  }

  getSalesReportByPackageDetail (id, payload) {
    this.setHeader(getAuthToken())
    return this.get(`/reports/sales-report/${id}/package/${payload}/detail`)
  }

  getVendorsSalesReport (query) {
    this.setHeader(getAuthToken())
    return this.get('/reports/vendors-sales-report', query)
  }
}

export default ReportsProvider
