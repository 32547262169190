<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="px-0 d-flex justify-space-between align-center">
        <v-btn
          color="primary"
          outlined
          depressed
          :ripple="false"
          style="width: fit-content; background-color: white;"
          @click="onBack()">
          <v-icon>
            mdi-chevron-left
          </v-icon>
          <span>
            ย้อนกลับ
          </span>
        </v-btn>
        <v-btn
          color="primary"
          outlined
          depressed
          :ripple="false"
          :loading="exporting"
          style="width: fit-content; background-color: white;"
          @click="onExport()">
          <v-icon class="mr-2">
            mdi-tray-arrow-up
          </v-icon>
          <span>
            นำออกข้อมูล
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-row class="form-container rounded-lg mb-1">
          <v-col cols="12">
            <h3 class="primary--text">
              รายละเอียดแพ็กเกจ
            </h3>
          </v-col>
          <v-col cols="12">
            <table>
              <tr>
                <td class="table-padding">
                  เลขที่เวนเดอร์
                </td>
                <td
                  v-if="vendorLoading"
                  class="pb-2">
                  -
                </td>
                <td
                  v-else
                  class="pb-2">
                  {{ vendor.vendorNo }}
                </td>
              </tr>
              <tr>
                <td class="table-padding">
                  เวนเดอร์
                </td>
                <td
                  v-if="vendorLoading"
                  class="pb-2">
                  -
                </td>
                <td
                  v-else
                  class="pb-2">
                  {{ vendor.name }}
                </td>
              </tr>
            </table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row class="form-container rounded-lg">
          <v-col cols="12">
            <report-filters
              v-model="query"
              @search="getItems(true)" />
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :sort-by.sync="query.sortBy"
              :sort-desc.sync="query.sortOrder"
              hide-default-footer
              class="table-border">
              <template #[`item.id`]="{ item }">
                <v-btn
                  class="px-0"
                  style="letter-spacing: 0;"
                  color="primary"
                  plain
                  :ripple="false"
                  :disabled="loading"
                  @click="showDetail(item)">
                  {{ item.id }}
                </v-btn>
              </template>
              <template #[`item.orderAmount`]="{ item }">
                {{ (item.orderAmount || 0) | showNumberFormat() }}
              </template>
              <template #[`item.orderSales`]="{ item }">
                ฿ {{ (item.orderSales || 0) | showNumberFormat() }}
              </template>
              <!-- <template #[`item.actions`]="{ item }">
                <v-btn
                  color="primary"
                  icon
                  :disabled="loading"
                  @click="showDetail(item)">
                  <v-icon>
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </template> -->
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-pagination
                v-model="query.page"
                :length="totalPages"
                :total-visible="7"
                circle
                color="primary" />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { ExportToCsv } from 'export-to-csv'
import ReportsProvider from '@/resources/reports.provider'
import VendorsProvider from '@/resources/vendors.provider'
import ReportFilters from '../components/ReportFilters.vue'

const ReportsService = new ReportsProvider()
const VendorsService = new VendorsProvider()

export default {
  components: { ReportFilters },
  data: () => ({
    vendorLoading: true,
    loading: true,
    exporting: false,
    headers: [
      {
        text: 'เลขที่แพ็กเกจทัวร์',
        value: 'id',
        class: 'table-header',
        align: 'center',
        sortable: false,
        width: '180'
      },
      {
        text: 'ชื่อแพ็กเกจทัวร์',
        value: 'name',
        class: 'table-header',
        cellClass: 'limit-text',
        sortable: false
      },
      {
        text: 'ยอดการจองทัวร์',
        value: 'orderAmount',
        class: 'table-header',
        align: 'center',
        width: '180'
      },
      {
        text: 'ยอดการขายรวม',
        value: 'orderSales',
        class: 'table-header',
        align: 'right',
        width: '180'
      }
      // {
      //   text: '',
      //   value: 'actions',
      //   class: 'table-header',
      //   sortable: false,
      //   align: 'right',
      //   width: '60'
      // }
    ],
    vendor: null,
    items: [],
    query: {
      sortBy: '',
      sortOrder: true,
      page: 1,
      limit: 10,
      search: '',
      continents: [],
      countries: [],
      vendorId: null,
      status: null,
      startDate: '',
      endDate: ''
    },
    totalPages: 1
  }),
  watch: {
    'query.sortBy': {
      handler () {
        this.getItems(true)
      },
      deep: true
    },
    'query.sortOrder': {
      handler () {
        this.getItems(true)
      },
      deep: true
    },
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    }
  },
  mounted () {
    this.query.vendorId = this.$route.params.id
    this.getVendor()
    this.getItems()
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'VendorsSalesReport' })
    },
    showDetail (item) {
      this.$router.push({
        name: 'SalesReportDetail',
        params: { id: item.id }
      })
    },
    async getVendor () {
      try {
        this.vendorLoading = true

        const { data } = await VendorsService.getItemById(this.query.vendorId)

        if (!data) {
          this.$store.dispatch('Snackbar/setSnackbar', {
            active: true,
            text: 'ไม่พบเวนเดอร์นี้',
            type: 'error',
            timeout: 2000
          })

          this.$router.push({ name: 'VendorsSalesReport' })
          return
        }

        this.vendor = data
      } catch (error) {
        console.error('getItems', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.vendorLoading = false
      }
    },
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await ReportsService.getSalesReport({
          ...this.query,
          sortBy: this.query.sortBy || 'createdAt',
          sortOrder: this.query.sortOrder || !this.query.sortBy ? 'desc' : 'asc'
        })

        this.items = data.results
        this.totalPages = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onExport () {
      try {
        this.exporting = true

        const { data } = await ReportsService.getSalesReport({
          ...this.query,
          page: 1,
          limit: 99999,
          sortBy: this.query.sortBy || 'createdAt',
          sortOrder: this.query.sortOrder || !this.query.sortBy ? 'desc' : 'asc'
        })

        const options = {
          filename: `vendor-sales-report-${this.vendor.vendorNo}`,
          showLabels: false,
          useKeysAsHeaders: false
        }

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv([
          {
            id: 'เลขที่แพ็กเกจทัวร์',
            name: 'ชื่อแพ็กเกจทัวร์',
            orderAmount: 'ยอดการจองทัวร์',
            orderSales: 'ยอดการขายรวม'
          },
          ...data.results.map((v) => ({
            id: v.id,
            name: v.name,
            orderAmount: v.orderAmount || 0,
            orderSales: v.orderSales || 0
          }))
        ])
      } catch (error) {
        console.error('onExport', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.exporting = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
.table-border {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
.table-padding {
  font-weight: 600;
  padding-right: 16px;
  padding-bottom: 8px;
}
</style>
