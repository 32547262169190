<template>
  <v-row>
    <v-col
      cols="12"
      md="3">
      <v-tabs
        v-model="tab"
        class="menu-tabs small-tabs"
        active-class="active-tab"
        hide-slider
        fixed-tabs>
        <!-- <v-tab>
          รายปี
        </v-tab> -->
        <v-tab>
          รายเดือน
        </v-tab>
        <v-tab>
          กำหนดเอง
        </v-tab>
      </v-tabs>
    </v-col>
    <v-col
      cols="12"
      md="3">
      <DatePickerDialog
        v-if="dateFormat.type === 'month'"
        v-model="date"
        :type="dateFormat.type"
        :format="dateFormat.format"
        :placeholder="dateFormat.placeholder"
        clearable />
      <DateRangePickerDialog
        v-else
        v-model="dateRange"
        :type="dateFormat.type"
        :format="dateFormat.format"
        :placeholder="dateFormat.placeholder"
        clearable />
    </v-col>
    <v-spacer />
    <v-col
      cols="12"
      md="4">
      <SearchBox
        v-model="query.search"
        :disabled="loading"
        @search="onSearch()" />
    </v-col>
  </v-row>
</template>

<script>
import DatePickerDialog from '@/components/DatePickerDialog.vue'
import DateRangePickerDialog from '@/components/DateRangePickerDialog.vue'
import SearchBox from '@/components/SearchBox.vue'

export default {
  components: { DatePickerDialog, DateRangePickerDialog, SearchBox },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    tab: null,
    date: '',
    dateRange: ['', '']
  }),
  computed: {
    query: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    dateFormat () {
      switch (this.tab) {
        case 0: return {
          type: 'month',
          format: 'MMMM BBBB',
          placeholder: 'เดือน ปี'
        }
        case 1: return {
          type: 'date',
          format: 'D MMM BB',
          placeholder: 'วัน เดือน ปี'
        }
        default: return {
          type: 'month',
          format: 'MMMM BBBB',
          placeholder: 'เดือน ปี'
        }
      }
    }
  },
  watch: {
    date () {
      this.onSearch()
    },
    dateRange () {
      this.onSearch()
    },
    'dateFormat.type': {
      handler () {
        this.onSearch()
      },
      deep: true
    }
  },
  methods: {
    onSearch () {
      if (this.date && this.dateFormat.type === 'month') {
        this.query.startDate = this.$dayjs(this.date)
          .date(1)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toISOString()
        this.query.endDate = this.$dayjs(this.date)
          .add(1, 'month')
          .subtract(1, 'd')
          .hour(23)
          .minute(59)
          .second(59)
          .millisecond(999)
          .toISOString()
      } else if (this.dateRange?.every((v) => Boolean(v)) && this.dateFormat.type === 'date') {
        this.query.startDate = this.$dayjs(this.dateRange[0])
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toISOString()
        this.query.endDate = this.$dayjs(this.dateRange[1])
          .add(1, 'd')
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .subtract(1, 'millisecond')
          .toISOString()
      } else {
        this.query.startDate = ''
        this.query.endDate = ''
      }

      this.$emit('search')
    }
  }
}
</script>

<style scoped>
.menu-tabs {
  border: 1px solid #026EAA;
  border-radius: 4px;
  padding: 4px;
}
.active-tab {
  background-color: #026EAA;
  border-radius: 3px;
  color: white;
}
</style>

<style>
.small-tabs .v-tabs-bar {
  height: 30px !important;
}
</style>
